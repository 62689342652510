<template>
	<div class="login_layout">
		<div class="main">
			<div class="main_portrait">
				<div class="box bg_primary">
					<!-- <img style="max-width:100%" src="https://h5.xunmeizaixian.com/picture/logo_jishi.gif" alt="" /> -->
					<img style="max-width:100%" :src="srcurl" alt="" />
				</div>
			</div>
			<div class="main_body">
				<h1 class="title">您好，请登录</h1>
				<div class="form">
					<van-field v-model="param.mobile" :clearable="true" placeholder="请输入手机号码" maxlength="11">
						<div class="slot-icon" slot="left-icon" style="width:40px; display: flex;align-items: center; justify-content: flex-end;">
							<img width="20px" height="20px" :src="phoneIcon" alt=""/>
						</div>
					</van-field>
					<van-field v-model="param.code" placeholder="请输入验证码" maxlength="6">
						<div class="slot-icon" slot="left-icon" style="width:40px; display: flex;align-items: center; justify-content: flex-end;">
							<img width="20px" height="20px" :src="codeIcon" alt=""/>
						</div>
						<p slot="button" v-if="isCodeCount" class="text_primary" style="text-decoration: underline; padding-right: 19px;font-size: 12px;">{{  codeCount  }}S</p>
						<p slot="button" v-else class="text_primary" @click="clickGetCode" style="text-decoration: underline; padding-right: 19px;font-size: 12px;">获取验证码</p>
					</van-field>
					<button class="btn" @click="clickLogin">登录</button>
					<p class="explain">
						<span class="radio border" @click="isReadAgree = !isReadAgree">
							<transition name="fade">
								<span v-show="isReadAgree" class="bg_primary"></span>
							</transition>
						</span>
						<span class="text">我已阅读并同意<span class="text_primary" @click="$router.push('/service-agreement')">《服务协议》</span>及<span class="text_primary" @click="$router.push('/privacy-policy')">《隐私协议》</span></span>
					</p>
				</div>
			</div>
		</div>
		<div class="other_login" style="display: block;">
			<div class="divider">
				<div class="line left"></div>
				<div class="text">其它登录方式</div>
				<div class="line right"></div>
			</div>
			<div class="login_option">
				<div class="phone_login" @click="$emit('toggleLogin', 'idcardLogin')">
					<img src="@/assets/icon-idcard-login.png" alt="">
					<p>身份证号登录</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Validation from '@/utils/validation'
import { getPhoneCode, phoneLogin, wxAuth } from '@/api/user'
import { getLoginMobile, setLoginMobile } from "@/utils/cookie";
import iconPhoneApp01 from '@/assets/icon-form-phone-app01.png'
import iconCodeApp01 from '@/assets/icon-form-code-app01.png'
import iconPhoneApp02 from '@/assets/icon-form-phone-app02.png'
import iconCodeApp02 from '@/assets/icon-form-code-app02.png'
import iconPhoneApp03 from '@/assets/icon-form-phone-app03.png'
import iconCodeApp03 from '@/assets/icon-form-code-app03.png'
export default {
	name: 'phoneLogin',
	data: () => ({
		imgs: {
			app01: [iconPhoneApp01, iconCodeApp01],
			app02: [iconPhoneApp02, iconCodeApp02],
			app03: [iconPhoneApp03, iconCodeApp03],
			app04: [iconPhoneApp01, iconCodeApp01],
			app05: [iconPhoneApp01, iconCodeApp01],
			app06: [iconPhoneApp01, iconCodeApp01],
			app08: [iconPhoneApp01, iconCodeApp01],
			app09: [iconPhoneApp01, iconCodeApp01],
			app10: [iconPhoneApp01, iconCodeApp01],
		},
		appno: 'app01',
		isReadAgree: false, // 是否同意协议
		codeCount: 60,
		isCodeCount: false,
		codeTimer: null,
		param: {
			mobile: '',
			code: ''
		},
		rules: {
			mobile: [
				{ required: true, message: '手机号不能为空' },
				{ count: 11, message: '手机号码位数输入有误，请重新输入' }
			],
			code: [
				{ required: true, message: '验证码不能为空' },
				{ count: 6, message: '验证码输入有误，请重新输入' }
			]
		},
		srcurl: '',
	}),
	created () {
		if (location.host.startsWith('shandong.')) {
			this.srcurl = 'https://h5.xunmeizaixian.com/picture/logo_haojia.gif'
		} else {
			this.srcurl = 'https://h5.xunmeizaixian.com/picture/logo_jishi.gif'
		}
	},
	mounted () {
		const appno = sessionStorage.getItem('appno')
		this.appno = appno ? appno.toLowerCase() : 'app01'
		if (typeof(getLoginMobile()) !== 'undefined') {
			this.param.mobile = getLoginMobile();
		}
	},
	computed: {
		phoneIcon () {
			return this.imgs[this.appno][0]
		},
		codeIcon () {
			return this.imgs[this.appno][1]
		}
	},
	methods: {
		loginVertify () {
			if (!this.isReadAgree) {
				this.$utils.dialog.alert('您需要同意服务协议及隐私策略才能操作。')
				return false
			}
			const message = new Validation(this.rules, this.param).start()
			if (message) {
				this.$utils.dialog.alert(message)
				return false
			}
			return true
		},
		clickLogin () {
			const _this = this
			if (this.loginVertify()) {
				phoneLogin(this.param).then(res => {
					if (res.code == 0 || res.code == 200) {
						const appNo = sessionStorage.getItem('appno') || 'APP01';
						localStorage.setItem('loginInfor_' + appNo, JSON.stringify({
							phone: this.param.mobile,
							token: res.toH5_token
						}));
						setLoginMobile(this.param.mobile);
						if (_this.$store.state.cache.screen.wx) {
							_this.wxAuthPay()
						} else {
							_this.$router.push({ path: '/auth', query: { phone: this.param.mobile, token: res.toH5_token }})
						}
					}
				})
			}
		},
		// 微信授权
		wxAuthPay () {
			let href = window.location.href.replace('login', 'auth')
			wxAuth('', href).then(({url}) => {
				window.location.href = url
			})
		},
		// 开始倒计时
		beginCodeComputed () {
			const _this = this
			this.codeCount = 60
			this.isCodeCount = true
			const beginTimetamp = new Date().getTime()
			this.codeTimer = setInterval(() => {
				const curTimetamp = new Date().getTime()
				const curTimeVal = Math.floor((curTimetamp - beginTimetamp) / 1000)
				if (curTimeVal >= 60) {
					clearInterval(_this.codeTimer)
					_this.isCodeCount = false
				} else {
					_this.codeCount = 60 - curTimeVal
				}
			}, 1000)
		},
		// 点击获取验证码
		clickGetCode () {
			const _this = this
			const message = new Validation({ mobile: this.rules.mobile }, { mobile: this.param.mobile}).start()
			if (message) {
				this.$utils.dialog.alert(message)
			} else {
				getPhoneCode({ mobile: this.param.mobile }).then((res) => {
					_this.beginCodeComputed()
					if (res.data.smsCode) {
						_this.param.code = res.data.smsCode
					}
					// _this.$utils.dialog.alert(res.msg)
				})
			}
		}
	}
}
</script>

<style scoped>
	.main_portrait .box { overflow: hidden }
</style>
